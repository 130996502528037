import React from 'react';
import './Gallery.css';
import image1 from '../img/security1.jpeg';
import image2 from '../img/gallery1.jpeg';
import image01 from '../img/h1.jpeg';
import image02 from '../img/h2.jpeg';
import image07 from '../img/h3.jpeg';
import image008 from '../img/h4.jpeg';
import image09 from '../img/h5.jpeg';
import image7 from '../img/w1.jpeg';
import image8 from '../img/w2.jpeg';
import image08 from '../img/z1.jpeg';
import imag1 from '../img/e1.jpeg';
import imag2 from '../img/e2.jpeg';
import imag7 from '../img/e3.jpeg';
import imag8 from '../img/e4.jpeg';
import imag9 from '../img/e5.jpeg';
import imagef1 from '../img/f1.jpeg';
import imagef2 from '../img/f2.jpeg';
import imagef7 from '../img/f3.jpeg';
import imagef8 from '../img/f4.jpeg';
import imagef9 from '../img/f5.jpeg';
import imagep1 from '../img/p1.jpeg';
import imagep2 from '../img/p2.jpeg';
import imagep7 from '../img/p3.jpeg';
import imagep8 from '../img/p4.jpeg';
import imagep9 from '../img/p5.jpeg';
import imagem1 from '../img/m1.jpeg';
import imagem2 from '../img/m2.jpeg';
import imagem7 from '../img/m3.jpeg';
import imagem8 from '../img/m4.jpeg';
import imagem9 from '../img/m5.jpeg';

import Footer from '../components/Footer';

import abtHeader from "../img/blog-header.jpg";
import InnerHeaderBanner from "../components/InnerHeaderBanner";
import InnerHeader from "../components/InnerHeader";


const Gallery = () => {
  return (
    <>
     <InnerHeader />
   <InnerHeaderBanner name={"Gallery"} img = {abtHeader}/>
     <div className='gallery2'>
     
    </div>
    <br/>
    <br/>
    <div className="section-header">
            <h2>Security Services </h2>
            
          </div>
       <div className='gallery45'>
      
         
       
         
        <div className='gallery1'>
            <img src={image1} />
        </div>
        
        <div className='gallery1'>
            <img src={image2} />
        </div>
        <div className='gallery1'>
            <img src={image7} />
        </div>
        
    </div>
    <div className='gallery45'>
    <div className='gallery1'>
            <img src={image8} />
        </div>
        <div className='gallery1'>
            <img src={image08} />
        </div>
        
       
        
    </div>


<br/>

    <div className="section-header">
            <h2>Soft Services - House Keeping </h2>
            
          </div>
       <div className='gallery45'>
      
         
       
         
        <div className='gallery1'>
            <img src={image01} />
        </div>
        
        <div className='gallery1'>
            <img src={image02} />
        </div>
        <div className='gallery1'>
            <img src={image07} />
        </div>
        
    </div>
    <div className='gallery45'>
    <div className='gallery1'>
            <img src={image008} />
        </div>
        <div className='gallery1'>
            <img src={image09} />
        </div>
        
       
        
    </div>


<br/>

    <div className="section-header">
            <h2>MEP Services </h2>
            
          </div>
       <div className='gallery45'>
      
         
       
         
        <div className='gallery1'>
            <img src={imag1} />
        </div>
        
        <div className='gallery1'>
            <img src={imag2} />
        </div>
        <div className='gallery1'>
            <img src={imag7} />
        </div>
        
    </div>
    <div className='gallery45'>
    <div className='gallery1'>
            <img src={imag8} />
        </div>
        <div className='gallery1'>
            <img src={imag9} />
        </div>
        
       
        
    </div>




<br/>
    <div className="section-header">
            <h2>Fire & Safety System </h2>
            
          </div>
       <div className='gallery45'>
      
         
       
         
        <div className='gallery1'>
            <img src={imagef1} />
        </div>
        
        <div className='gallery1'>
            <img src={imagef2} />
        </div>
        <div className='gallery1'>
            <img src={imagef7} />
        </div>
        
    </div>
    <div className='gallery45'>
    <div className='gallery1'>
            <img src={imagef8} />
        </div>
        <div className='gallery1'>
            <img src={imagef9} />
        </div>
        
       
        
    </div>




<br/>
    <div className="section-header">
            <h2>Pest Control Services </h2>
            
          </div>
       <div className='gallery45'>
      
         
       
         
        <div className='gallery1'>
            <img src={imagep1} />
        </div>
        
        <div className='gallery1'>
            <img src={imagep2} />
        </div>
        <div className='gallery1'>
            <img src={imagep7} />
        </div>
        
    </div>
    <div className='gallery45'>
    <div className='gallery1'>
            <img src={imagep8} />
        </div>
        <div className='gallery1'>
            <img src={imagep9} />
        </div>
        
       
        
    </div>



<br/>

    <div className="section-header">
            <h2>Property Management </h2>
            
          </div>
       <div className='gallery45'>
      
         
       
         
        <div className='gallery1'>
            <img src={imagem1} />
        </div>
        
        <div className='gallery1'>
            <img src={imagem2} />
        </div>
        <div className='gallery1'>
            <img src={imagem7} />
        </div>
        
    </div>
    <div className='gallery45'>
    <div className='gallery1'>
            <img src={imagem8} />
        </div>
        <div className='gallery1'>
            <img src={imagem9} />
        </div>
        
       
        
    </div>


    

   
    <Footer/>

    <br/>
    <br/>
    </>
  )
}

export default Gallery