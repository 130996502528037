import React from "react";
import footerLogo from '../img/css-logo.png'
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTty } from '@fortawesome/free-solid-svg-icons';




import { HashLink } from "react-router-hash-link";


const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-12 footer-info">
                <Link to="/" className="logo d-flex align-items-center">
                  <img style={{width:'150px'}} src={footerLogo} alt="" title="" />
                </Link>
                <p>
                We Provide the Best security Services in bangalore with the over 100+ Clients And 15+ professional team. We provides wide range of Security, Manpower, House-Keeping and Detective related services. Event Security Management; Skilled & Unskilled Manpower Service; Temporary Staffing; Facility Management; Housekeeping and Investigation Services. 
                </p>
                {/* <div className="social-links d-flex  mt-3">
                  <Link to="/" className="twitter">
                    <i className="bi bi-twitter"></i>
                  </Link>

                  <Link to="/" className="facebook">
                    <i className="bi bi-facebook"></i>
                  </Link>
                  
                  <Link to ="https://www.linkedin.com/company/symbiotic-infotech-pvt-ltd/" className="linkedin">
                    <i className="bi bi-linkedin"></i>
                  </Link>
                </div> */}
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-dash"></i>
                    <Link to ="/">Home</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <Link to ="/services">Services</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <Link to="/careers">Gallery</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <Link to="/careers">Clients</Link>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <Link to ="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bi bi-dash"></i>
                    <HashLink smooth to ="/services/#Educational-content">
                      Security Services
                    </HashLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <HashLink smooth to ="/services/#Entertainment-content">
                      Soft Services
                    </HashLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <HashLink smooth to="/services/#Games">MEP Services</HashLink>
                  </li>
                  <li>
                    <i className="bi bi-dash"></i>
                    <HashLink smooth to ="/services/#Sports">fire And Safety team</HashLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <address>
                 
                #2M - 138,1st Floor, 2nd Main, East of NGEF, Kasturi Nagar, Bangalore 560043. Landmark: Opp. Bangalore One
                  <br />
                  <strong><i className="bi bi-envelope"></i> </strong>
      <a href="mailto:info@symbioticinfo.com">
        gopal@cssindia.co<br/>
      
      </a>
                  <strong><i className="bi bi-telephone"></i> </strong> +91 8197422345<br />
                  <strong><i className="bi bi-envelope"></i> </strong>
      <a href="mailto:info@symbioticinfo.com">
      Manjit@cssindia.co<br/>
      
      </a>
      <strong><i className="bi bi-telephone"></i> </strong> +91 9035631032<br />
      <strong><i className="bi bi-envelope"></i> </strong>
      <a href="mailto:info@symbioticinfo.com">
      umesh@cssindia.co<br/>
      
      </a>
      <strong><i className="bi bi-telephone"></i> </strong> +91 8310106303<br />
      <strong><i className="bi bi-telephone
      "></i> </strong> 08025424016<br />
      
      
      
       
                  <br />
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal">
          <div className="container">
            <div className="copyright">
              <span id="copyright">
                <script>
                  document.getElementById('copyright').appendChild(document.createTextNode(new
                  Date().getFullYear()) )
                </script>
              </span>
              &copy; 2024 CSS - 
              <span style={{color:'blue'}}> Complete Security Services Copyright </span> | All Rights Reserved
              <br/>Designed and Developed by <a href="https://teciexsolutions.com">Teciex solution Pvt. Ltd.</a>
            </div>
          </div>
        </div>
      </footer>
      
    </>
  )
  
};

export default Footer;
